<template>
  <button class="btn-game-hollow">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 322 404"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xml:space="preserve"
      xmlns:serif="http://www.serif.com/"
      style="
        fill-rule: evenodd;
        clip-rule: evenodd;
        stroke-linejoin: round;
        stroke-miterlimit: 2;
      "
    >
      <g transform="matrix(1,0,0,1,-5056.53,-7096.04)">
        <g>
          <g transform="matrix(1.54539,0,0,1.14988,-2792.27,-1111.79)">
            <path
              d="M5139.18,7178.54C5139.18,7156.15 5125.66,7137.98 5109,7137.98C5092.35,7137.98 5078.83,7156.15 5078.83,7178.54L5078.83,7448.62C5078.83,7471 5092.35,7489.17 5109,7489.17C5125.66,7489.17 5139.18,7471 5139.18,7448.62L5139.18,7178.54Z"
              style="fill: white"
            />
          </g>
          <g transform="matrix(1.54539,0,0,1.14988,-2563.75,-1111.79)">
            <path
              d="M5139.18,7178.54C5139.18,7156.15 5125.66,7137.98 5109,7137.98C5092.35,7137.98 5078.83,7156.15 5078.83,7178.54L5078.83,7448.62C5078.83,7471 5092.35,7489.17 5109,7489.17C5125.66,7489.17 5139.18,7471 5139.18,7448.62L5139.18,7178.54Z"
              style="fill: white"
            />
          </g>
        </g>
      </g>
    </svg>
    <span>Pause</span>
  </button>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
svg {
  width: 0.8rem;
  margin-right: 0.5rem;
  display: inline-block;
}
</style>
