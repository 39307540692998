<template name="unauthorized">
  <div id="unauthorized">
    <h1>Oops! You are not authorized</h1>
    <p>Please contact the sysadmin to get access to this page</p>
  </div>
</template>

<style></style>

<script>
/**
 * The unauthorized page to show if the user isn't authorized to see the route.
 */
export default {
  name: "unauthorized",
  data() {
    return {};
  },
};
</script>
