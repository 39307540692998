<template>
  <svg
    viewBox="0 0 142 52"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <g transform="matrix(1,0,0,1,-0.00021875,-1.25e-05)">
      <g transform="matrix(1,0,0,1,-114.699,-39.1953)">
        <g transform="matrix(1,0,0,1,113,69)">
          <g transform="matrix(40,0,0,40,0,0)">
            <path
              d="M0.328,0.019C0.418,0.019 0.489,-0.002 0.541,-0.042C0.593,-0.082 0.62,-0.137 0.62,-0.206C0.62,-0.231 0.616,-0.253 0.609,-0.273C0.601,-0.293 0.592,-0.31 0.579,-0.325C0.567,-0.339 0.551,-0.352 0.531,-0.365C0.511,-0.377 0.49,-0.387 0.469,-0.396C0.447,-0.404 0.422,-0.413 0.392,-0.422C0.383,-0.425 0.371,-0.427 0.354,-0.429C0.337,-0.431 0.324,-0.434 0.316,-0.437C0.283,-0.448 0.257,-0.46 0.24,-0.472C0.222,-0.485 0.213,-0.502 0.213,-0.522C0.213,-0.539 0.22,-0.553 0.232,-0.564C0.245,-0.575 0.261,-0.583 0.279,-0.588C0.298,-0.593 0.318,-0.595 0.34,-0.595C0.374,-0.595 0.403,-0.592 0.427,-0.586C0.451,-0.579 0.478,-0.568 0.508,-0.551C0.518,-0.547 0.528,-0.546 0.539,-0.548C0.549,-0.55 0.557,-0.556 0.565,-0.564C0.572,-0.573 0.578,-0.583 0.582,-0.593C0.585,-0.604 0.586,-0.615 0.583,-0.628C0.581,-0.641 0.575,-0.652 0.565,-0.661C0.542,-0.683 0.508,-0.699 0.464,-0.71C0.419,-0.721 0.376,-0.726 0.333,-0.726C0.244,-0.726 0.174,-0.706 0.121,-0.667C0.069,-0.628 0.042,-0.575 0.042,-0.509C0.042,-0.461 0.055,-0.423 0.08,-0.395C0.105,-0.368 0.148,-0.343 0.209,-0.32L0.371,-0.266C0.421,-0.25 0.446,-0.224 0.446,-0.19C0.446,-0.164 0.435,-0.145 0.412,-0.132C0.389,-0.119 0.359,-0.113 0.323,-0.113C0.269,-0.113 0.214,-0.125 0.159,-0.148C0.145,-0.155 0.133,-0.157 0.122,-0.156C0.111,-0.155 0.101,-0.151 0.093,-0.145C0.084,-0.139 0.077,-0.131 0.072,-0.121C0.067,-0.111 0.064,-0.1 0.063,-0.09C0.062,-0.079 0.064,-0.069 0.068,-0.059C0.073,-0.049 0.08,-0.04 0.09,-0.034C0.147,0.001 0.226,0.019 0.328,0.019Z"
              :style="{ fill: fillColor, fillRule: 'nonzero' }"
            />
            />
          </g>
          <g transform="matrix(40,0,0,40,26.0547,0)">
            <path
              d="M0.625,0C0.651,0 0.672,-0.01 0.687,-0.031C0.702,-0.052 0.717,-0.088 0.73,-0.139L0.813,-0.396C0.822,-0.428 0.817,-0.453 0.798,-0.473C0.783,-0.488 0.765,-0.494 0.743,-0.491C0.719,-0.488 0.701,-0.472 0.689,-0.446C0.686,-0.44 0.684,-0.434 0.683,-0.428L0.604,-0.153L0.528,-0.385L0.518,-0.421C0.515,-0.429 0.513,-0.435 0.512,-0.437C0.51,-0.44 0.508,-0.444 0.505,-0.451C0.502,-0.458 0.5,-0.462 0.498,-0.464C0.496,-0.466 0.493,-0.469 0.489,-0.474C0.486,-0.478 0.482,-0.481 0.478,-0.483C0.475,-0.485 0.47,-0.486 0.465,-0.489C0.46,-0.491 0.455,-0.493 0.449,-0.493C0.444,-0.494 0.438,-0.494 0.431,-0.494C0.421,-0.494 0.413,-0.493 0.405,-0.491C0.397,-0.489 0.39,-0.487 0.385,-0.484C0.379,-0.482 0.374,-0.477 0.369,-0.471C0.364,-0.465 0.36,-0.459 0.358,-0.456C0.355,-0.452 0.352,-0.445 0.348,-0.434C0.345,-0.424 0.342,-0.417 0.341,-0.412C0.339,-0.407 0.337,-0.398 0.333,-0.385L0.257,-0.153L0.178,-0.428C0.17,-0.462 0.153,-0.482 0.127,-0.49C0.102,-0.498 0.08,-0.492 0.062,-0.473C0.044,-0.453 0.039,-0.428 0.048,-0.396L0.13,-0.139C0.144,-0.088 0.158,-0.052 0.174,-0.031C0.189,-0.01 0.209,0 0.236,0C0.259,0 0.278,-0.003 0.291,-0.01C0.304,-0.017 0.315,-0.031 0.325,-0.051C0.336,-0.07 0.347,-0.101 0.358,-0.142L0.431,-0.362L0.503,-0.142C0.514,-0.101 0.525,-0.07 0.535,-0.051C0.546,-0.031 0.557,-0.017 0.57,-0.01C0.583,-0.003 0.602,0 0.625,0Z"
              :style="{ fill: fillColor, fillRule: 'nonzero' }"
            />
            />
          </g>
          <g transform="matrix(40,0,0,40,60.1758,0)">
            <path
              d="M0.144,-0.59C0.169,-0.59 0.19,-0.597 0.205,-0.611C0.221,-0.626 0.229,-0.645 0.229,-0.668C0.229,-0.691 0.221,-0.71 0.206,-0.724C0.19,-0.738 0.17,-0.745 0.145,-0.745C0.119,-0.745 0.099,-0.738 0.083,-0.724C0.067,-0.71 0.06,-0.691 0.06,-0.668C0.06,-0.645 0.067,-0.626 0.083,-0.611C0.099,-0.597 0.119,-0.59 0.144,-0.59ZM0.146,0C0.173,0 0.193,-0.009 0.204,-0.026C0.215,-0.043 0.221,-0.069 0.221,-0.104L0.221,-0.388C0.221,-0.422 0.215,-0.448 0.202,-0.466C0.189,-0.483 0.169,-0.492 0.141,-0.492C0.114,-0.492 0.095,-0.483 0.084,-0.466C0.072,-0.448 0.066,-0.422 0.066,-0.388L0.066,-0.104C0.066,-0.069 0.073,-0.043 0.085,-0.026C0.097,-0.009 0.117,0 0.146,0Z"
              :style="{ fill: fillColor, fillRule: 'nonzero' }"
            />
            />
          </g>
          <g transform="matrix(40,0,0,40,71.3867,0)">
            <path
              d="M0.325,-0.096C0.289,-0.096 0.262,-0.108 0.242,-0.134C0.222,-0.159 0.212,-0.197 0.212,-0.248C0.212,-0.349 0.251,-0.4 0.328,-0.4C0.347,-0.4 0.364,-0.396 0.379,-0.387C0.394,-0.378 0.405,-0.366 0.413,-0.351C0.421,-0.337 0.427,-0.321 0.431,-0.304C0.435,-0.287 0.437,-0.269 0.437,-0.25C0.437,-0.202 0.428,-0.165 0.409,-0.137C0.39,-0.11 0.362,-0.096 0.325,-0.096ZM0.131,0.18C0.185,0.18 0.212,0.145 0.212,0.075L0.212,-0.078C0.224,-0.052 0.244,-0.031 0.271,-0.016C0.299,-0.002 0.331,0.006 0.367,0.006C0.397,0.006 0.425,0.001 0.451,-0.01C0.477,-0.02 0.5,-0.035 0.521,-0.055C0.541,-0.075 0.557,-0.102 0.568,-0.134C0.58,-0.166 0.585,-0.203 0.585,-0.245C0.585,-0.327 0.566,-0.391 0.526,-0.435C0.487,-0.479 0.436,-0.501 0.374,-0.501C0.336,-0.501 0.304,-0.494 0.276,-0.479C0.247,-0.464 0.226,-0.44 0.211,-0.409L0.211,-0.415C0.211,-0.438 0.205,-0.456 0.192,-0.47C0.178,-0.485 0.16,-0.492 0.136,-0.492C0.109,-0.492 0.09,-0.482 0.076,-0.462C0.063,-0.443 0.057,-0.416 0.057,-0.381L0.057,0.075C0.057,0.11 0.063,0.136 0.074,0.154C0.086,0.171 0.105,0.18 0.131,0.18Z"
              :style="{ fill: fillColor, fillRule: 'nonzero' }"
            />
            />
          </g>
          <g transform="matrix(40,0,0,40,96.3672,0)">
            <path
              d="M0.194,-0.293C0.196,-0.328 0.206,-0.356 0.224,-0.378C0.242,-0.4 0.266,-0.411 0.298,-0.411C0.332,-0.411 0.356,-0.4 0.37,-0.38C0.385,-0.36 0.393,-0.331 0.396,-0.293L0.194,-0.293ZM0.303,0.006C0.328,0.006 0.352,0.004 0.374,-0.001C0.395,-0.005 0.414,-0.012 0.431,-0.02C0.447,-0.028 0.461,-0.036 0.471,-0.043C0.481,-0.05 0.491,-0.059 0.502,-0.068C0.515,-0.08 0.518,-0.096 0.513,-0.114C0.508,-0.132 0.498,-0.145 0.484,-0.151C0.47,-0.158 0.456,-0.158 0.443,-0.149C0.427,-0.136 0.409,-0.125 0.387,-0.116C0.366,-0.107 0.344,-0.103 0.321,-0.103C0.239,-0.103 0.196,-0.143 0.193,-0.222L0.476,-0.222C0.509,-0.222 0.525,-0.236 0.525,-0.262C0.525,-0.295 0.521,-0.326 0.512,-0.354C0.503,-0.382 0.49,-0.407 0.473,-0.429C0.455,-0.451 0.431,-0.469 0.401,-0.481C0.371,-0.494 0.336,-0.5 0.296,-0.5C0.246,-0.5 0.201,-0.489 0.162,-0.467C0.123,-0.445 0.092,-0.415 0.071,-0.377C0.05,-0.338 0.039,-0.296 0.039,-0.248C0.039,-0.193 0.051,-0.146 0.074,-0.107C0.097,-0.069 0.128,-0.04 0.167,-0.022C0.207,-0.003 0.252,0.006 0.303,0.006Z"
              :style="{ fill: fillColor, fillRule: 'nonzero' }"
            />
            />
          </g>
          <g transform="matrix(40,0,0,40,118.867,0)">
            <path
              d="M0.328,0.019C0.418,0.019 0.489,-0.002 0.541,-0.042C0.593,-0.082 0.62,-0.137 0.62,-0.206C0.62,-0.231 0.616,-0.253 0.609,-0.273C0.601,-0.293 0.592,-0.31 0.579,-0.325C0.567,-0.339 0.551,-0.352 0.531,-0.365C0.511,-0.377 0.49,-0.387 0.469,-0.396C0.447,-0.404 0.422,-0.413 0.392,-0.422C0.383,-0.425 0.371,-0.427 0.354,-0.429C0.337,-0.431 0.324,-0.434 0.316,-0.437C0.283,-0.448 0.257,-0.46 0.24,-0.472C0.222,-0.485 0.213,-0.502 0.213,-0.522C0.213,-0.539 0.22,-0.553 0.232,-0.564C0.245,-0.575 0.261,-0.583 0.279,-0.588C0.298,-0.593 0.318,-0.595 0.34,-0.595C0.374,-0.595 0.403,-0.592 0.427,-0.586C0.451,-0.579 0.478,-0.568 0.508,-0.551C0.518,-0.547 0.528,-0.546 0.539,-0.548C0.549,-0.55 0.557,-0.556 0.565,-0.564C0.572,-0.573 0.578,-0.583 0.582,-0.593C0.585,-0.604 0.586,-0.615 0.583,-0.628C0.581,-0.641 0.575,-0.652 0.565,-0.661C0.542,-0.683 0.508,-0.699 0.464,-0.71C0.419,-0.721 0.376,-0.726 0.333,-0.726C0.244,-0.726 0.174,-0.706 0.121,-0.667C0.069,-0.628 0.042,-0.575 0.042,-0.509C0.042,-0.461 0.055,-0.423 0.08,-0.395C0.105,-0.368 0.148,-0.343 0.209,-0.32L0.371,-0.266C0.421,-0.25 0.446,-0.224 0.446,-0.19C0.446,-0.164 0.435,-0.145 0.412,-0.132C0.389,-0.119 0.359,-0.113 0.323,-0.113C0.269,-0.113 0.214,-0.125 0.159,-0.148C0.145,-0.155 0.133,-0.157 0.122,-0.156C0.111,-0.155 0.101,-0.151 0.093,-0.145C0.084,-0.139 0.077,-0.131 0.072,-0.121C0.067,-0.111 0.064,-0.1 0.063,-0.09C0.062,-0.079 0.064,-0.069 0.068,-0.059C0.073,-0.049 0.08,-0.04 0.09,-0.034C0.147,0.001 0.226,0.019 0.328,0.019Z"
              :style="{ fill: fillColor, fillRule: 'nonzero' }"
            />
            />
          </g>
        </g>
      </g>
      <g transform="matrix(1,0,0,1,-114.699,-39.1953)">
        <g transform="matrix(1,0,0,1,132,90)">
          <g transform="matrix(16,0,0,16,0,0)">
            <path
              d="M0.163,0C0.188,0 0.209,-0.01 0.227,-0.03C0.245,-0.05 0.253,-0.074 0.253,-0.102L0.254,-0.297L0.476,-0.297C0.493,-0.297 0.508,-0.303 0.52,-0.316C0.532,-0.328 0.539,-0.344 0.539,-0.362C0.539,-0.381 0.532,-0.397 0.52,-0.41C0.508,-0.422 0.493,-0.429 0.476,-0.429L0.254,-0.429L0.254,-0.579L0.491,-0.579C0.508,-0.579 0.523,-0.585 0.535,-0.597C0.548,-0.61 0.554,-0.625 0.554,-0.644C0.554,-0.663 0.548,-0.678 0.535,-0.691C0.523,-0.704 0.508,-0.71 0.491,-0.71L0.187,-0.71C0.154,-0.71 0.127,-0.702 0.105,-0.687C0.084,-0.671 0.073,-0.647 0.073,-0.615L0.073,-0.102C0.073,-0.074 0.082,-0.05 0.1,-0.03C0.117,-0.01 0.138,0 0.163,0Z"
              :style="{ fill: fillColor, fillRule: 'nonzero' }"
            />
            />
          </g>
          <g transform="matrix(16,0,0,16,9.10938,0)">
            <path
              d="M0.399,0.019C0.514,0.019 0.601,-0.013 0.659,-0.075C0.718,-0.137 0.748,-0.23 0.748,-0.354C0.748,-0.478 0.718,-0.571 0.659,-0.633C0.601,-0.695 0.514,-0.726 0.399,-0.726C0.284,-0.726 0.197,-0.695 0.138,-0.633C0.079,-0.571 0.05,-0.478 0.05,-0.354C0.05,-0.23 0.079,-0.137 0.138,-0.075C0.197,-0.013 0.284,0.019 0.399,0.019ZM0.399,-0.113C0.341,-0.113 0.3,-0.131 0.276,-0.167C0.251,-0.203 0.239,-0.265 0.239,-0.354C0.239,-0.414 0.244,-0.461 0.255,-0.497C0.266,-0.532 0.283,-0.557 0.306,-0.572C0.329,-0.588 0.36,-0.595 0.399,-0.595C0.457,-0.595 0.498,-0.577 0.523,-0.541C0.547,-0.505 0.56,-0.442 0.56,-0.354C0.56,-0.265 0.547,-0.203 0.523,-0.167C0.498,-0.131 0.457,-0.113 0.399,-0.113Z"
              :style="{ fill: fillColor, fillRule: 'nonzero' }"
            />
            />
          </g>
          <g transform="matrix(16,0,0,16,21.8672,0)">
            <path
              d="M0.25,-0.405L0.25,-0.6L0.326,-0.6C0.339,-0.6 0.35,-0.599 0.359,-0.599C0.367,-0.599 0.378,-0.598 0.389,-0.597C0.401,-0.595 0.411,-0.594 0.418,-0.591C0.426,-0.589 0.434,-0.585 0.442,-0.58C0.45,-0.575 0.457,-0.569 0.462,-0.563C0.466,-0.556 0.47,-0.547 0.473,-0.537C0.476,-0.527 0.478,-0.515 0.478,-0.501C0.478,-0.482 0.474,-0.465 0.465,-0.452C0.457,-0.438 0.445,-0.428 0.429,-0.422C0.414,-0.416 0.399,-0.411 0.383,-0.409C0.368,-0.406 0.35,-0.405 0.33,-0.405L0.25,-0.405ZM0.163,0C0.189,0 0.211,-0.009 0.228,-0.026C0.245,-0.044 0.253,-0.069 0.253,-0.102L0.253,-0.289L0.354,-0.289C0.392,-0.289 0.42,-0.281 0.436,-0.264C0.453,-0.248 0.463,-0.221 0.467,-0.184L0.474,-0.12C0.477,-0.078 0.486,-0.047 0.501,-0.028C0.516,-0.009 0.539,0 0.568,0C0.602,0 0.626,-0.011 0.639,-0.034C0.652,-0.056 0.655,-0.092 0.65,-0.143L0.641,-0.208C0.637,-0.236 0.633,-0.258 0.628,-0.276C0.622,-0.293 0.614,-0.308 0.603,-0.321C0.592,-0.334 0.579,-0.344 0.562,-0.35C0.545,-0.356 0.524,-0.361 0.498,-0.364L0.498,-0.366C0.602,-0.384 0.655,-0.44 0.655,-0.533C0.655,-0.562 0.65,-0.587 0.64,-0.608C0.63,-0.63 0.616,-0.647 0.6,-0.66C0.584,-0.673 0.563,-0.683 0.539,-0.691C0.514,-0.699 0.49,-0.704 0.465,-0.707C0.44,-0.71 0.412,-0.711 0.38,-0.711L0.184,-0.711C0.152,-0.711 0.126,-0.703 0.105,-0.687C0.084,-0.671 0.073,-0.647 0.073,-0.616L0.073,-0.102C0.073,-0.07 0.082,-0.045 0.099,-0.027C0.116,-0.009 0.137,0 0.163,0Z"
              :style="{ fill: fillColor, fillRule: 'nonzero' }"
            />
            />
          </g>
          <g transform="matrix(16,0,0,16,37.5859,0)">
            <path
              d="M0.328,0.019C0.418,0.019 0.489,-0.002 0.541,-0.042C0.593,-0.082 0.62,-0.137 0.62,-0.206C0.62,-0.231 0.616,-0.253 0.609,-0.273C0.601,-0.293 0.592,-0.31 0.579,-0.325C0.567,-0.339 0.551,-0.352 0.531,-0.365C0.511,-0.377 0.49,-0.387 0.469,-0.396C0.447,-0.404 0.422,-0.413 0.392,-0.422C0.383,-0.425 0.371,-0.427 0.354,-0.429C0.337,-0.431 0.324,-0.434 0.316,-0.437C0.283,-0.448 0.257,-0.46 0.24,-0.472C0.222,-0.485 0.213,-0.502 0.213,-0.522C0.213,-0.539 0.22,-0.553 0.232,-0.564C0.245,-0.575 0.261,-0.583 0.279,-0.588C0.298,-0.593 0.318,-0.595 0.34,-0.595C0.374,-0.595 0.403,-0.592 0.427,-0.586C0.451,-0.579 0.478,-0.568 0.508,-0.551C0.518,-0.547 0.528,-0.546 0.539,-0.548C0.549,-0.55 0.557,-0.556 0.565,-0.564C0.572,-0.573 0.578,-0.583 0.582,-0.593C0.585,-0.604 0.586,-0.615 0.583,-0.628C0.581,-0.641 0.575,-0.652 0.565,-0.661C0.542,-0.683 0.508,-0.699 0.464,-0.71C0.419,-0.721 0.376,-0.726 0.333,-0.726C0.244,-0.726 0.174,-0.706 0.121,-0.667C0.069,-0.628 0.042,-0.575 0.042,-0.509C0.042,-0.461 0.055,-0.423 0.08,-0.395C0.105,-0.368 0.148,-0.343 0.209,-0.32L0.371,-0.266C0.421,-0.25 0.446,-0.224 0.446,-0.19C0.446,-0.164 0.435,-0.145 0.412,-0.132C0.389,-0.119 0.359,-0.113 0.323,-0.113C0.269,-0.113 0.214,-0.125 0.159,-0.148C0.145,-0.155 0.133,-0.157 0.122,-0.156C0.111,-0.155 0.101,-0.151 0.093,-0.145C0.084,-0.139 0.077,-0.131 0.072,-0.121C0.067,-0.111 0.064,-0.1 0.063,-0.09C0.062,-0.079 0.064,-0.069 0.068,-0.059C0.073,-0.049 0.08,-0.04 0.09,-0.034C0.147,0.001 0.226,0.019 0.328,0.019Z"
              :style="{ fill: fillColor, fillRule: 'nonzero' }"
            />
            />
          </g>
          <g transform="matrix(16,0,0,16,48.0078,0)">
            <path
              d="M0.4,0.019C0.489,0.019 0.562,-0.004 0.62,-0.048C0.647,-0.07 0.659,-0.095 0.654,-0.123C0.651,-0.142 0.641,-0.157 0.625,-0.167C0.61,-0.176 0.595,-0.177 0.581,-0.169C0.556,-0.155 0.529,-0.144 0.498,-0.136C0.467,-0.128 0.44,-0.124 0.415,-0.124C0.356,-0.124 0.311,-0.143 0.281,-0.182C0.251,-0.221 0.236,-0.278 0.236,-0.353C0.236,-0.392 0.24,-0.425 0.247,-0.454C0.253,-0.482 0.262,-0.505 0.273,-0.521C0.283,-0.538 0.297,-0.551 0.314,-0.56C0.331,-0.57 0.348,-0.576 0.364,-0.579C0.38,-0.582 0.4,-0.583 0.421,-0.583C0.462,-0.583 0.507,-0.573 0.556,-0.551C0.568,-0.547 0.579,-0.544 0.589,-0.544C0.6,-0.544 0.609,-0.547 0.616,-0.552C0.624,-0.557 0.63,-0.563 0.636,-0.571C0.642,-0.578 0.645,-0.586 0.647,-0.596C0.649,-0.606 0.649,-0.616 0.647,-0.625C0.646,-0.634 0.642,-0.644 0.636,-0.653C0.63,-0.663 0.622,-0.671 0.611,-0.678C0.58,-0.694 0.554,-0.705 0.531,-0.711C0.495,-0.721 0.455,-0.726 0.411,-0.726C0.36,-0.726 0.312,-0.717 0.268,-0.7C0.224,-0.683 0.186,-0.658 0.154,-0.627C0.121,-0.595 0.096,-0.555 0.077,-0.507C0.059,-0.459 0.05,-0.406 0.05,-0.348C0.05,-0.29 0.059,-0.237 0.077,-0.19C0.096,-0.144 0.121,-0.105 0.153,-0.075C0.185,-0.045 0.222,-0.022 0.264,-0.006C0.306,0.01 0.352,0.019 0.4,0.019Z"
              :style="{ fill: fillColor, fillRule: 'nonzero' }"
            />
            />
          </g>
          <g transform="matrix(16,0,0,16,58.9531,0)">
            <path
              d="M0.167,0C0.193,0 0.214,-0.01 0.231,-0.03C0.249,-0.05 0.258,-0.074 0.258,-0.102L0.258,-0.609C0.258,-0.637 0.249,-0.661 0.231,-0.681C0.214,-0.701 0.193,-0.711 0.168,-0.711C0.143,-0.711 0.122,-0.701 0.104,-0.681C0.087,-0.661 0.078,-0.637 0.078,-0.609L0.078,-0.102C0.078,-0.074 0.087,-0.05 0.104,-0.03C0.122,-0.01 0.143,0 0.167,0Z"
              :style="{ fill: fillColor, fillRule: 'nonzero' }"
            />
            />
          </g>
          <g transform="matrix(16,0,0,16,64.3281,0)">
            <path
              d="M0.161,0L0.512,0C0.53,0 0.545,-0.006 0.557,-0.019C0.569,-0.031 0.575,-0.047 0.575,-0.065C0.575,-0.084 0.569,-0.1 0.557,-0.112C0.545,-0.125 0.53,-0.131 0.512,-0.131L0.248,-0.131L0.248,-0.295L0.478,-0.295C0.497,-0.295 0.513,-0.301 0.526,-0.314C0.539,-0.326 0.546,-0.342 0.546,-0.36C0.546,-0.379 0.539,-0.395 0.526,-0.407C0.513,-0.42 0.497,-0.427 0.478,-0.427L0.248,-0.427L0.248,-0.579L0.5,-0.579C0.519,-0.579 0.535,-0.585 0.548,-0.597C0.561,-0.61 0.568,-0.625 0.568,-0.644C0.568,-0.663 0.561,-0.678 0.548,-0.691C0.535,-0.704 0.519,-0.71 0.5,-0.71L0.186,-0.71C0.166,-0.71 0.149,-0.708 0.135,-0.703C0.122,-0.699 0.111,-0.692 0.103,-0.685C0.095,-0.677 0.089,-0.666 0.084,-0.654C0.079,-0.641 0.076,-0.628 0.075,-0.615C0.074,-0.603 0.073,-0.587 0.073,-0.568L0.073,-0.125C0.073,-0.109 0.075,-0.095 0.078,-0.081C0.081,-0.067 0.086,-0.053 0.092,-0.041C0.099,-0.028 0.108,-0.018 0.12,-0.011C0.131,-0.004 0.145,0 0.161,0Z"
              :style="{ fill: fillColor, fillRule: 'nonzero' }"
            />
            />
          </g>
          <g transform="matrix(16,0,0,16,74.0859,0)">
            <path
              d="M0.149,0C0.17,0 0.188,-0.008 0.203,-0.024C0.219,-0.04 0.226,-0.059 0.226,-0.081L0.226,-0.465L0.518,-0.083C0.536,-0.057 0.554,-0.036 0.571,-0.022C0.589,-0.007 0.607,0 0.625,0C0.643,0 0.658,-0.004 0.67,-0.012C0.682,-0.02 0.691,-0.031 0.697,-0.046C0.703,-0.06 0.707,-0.076 0.709,-0.092C0.711,-0.107 0.712,-0.126 0.712,-0.147L0.712,-0.63C0.712,-0.652 0.705,-0.671 0.69,-0.687C0.675,-0.703 0.657,-0.711 0.636,-0.711C0.615,-0.711 0.597,-0.703 0.582,-0.687C0.567,-0.671 0.56,-0.652 0.56,-0.63L0.56,-0.252L0.271,-0.627C0.234,-0.683 0.198,-0.711 0.162,-0.711C0.137,-0.711 0.116,-0.702 0.099,-0.683C0.082,-0.665 0.073,-0.639 0.073,-0.607L0.073,-0.081C0.073,-0.059 0.081,-0.04 0.095,-0.024C0.11,-0.008 0.128,0 0.149,0Z"
              :style="{ fill: fillColor, fillRule: 'nonzero' }"
            />
            />
          </g>
          <g transform="matrix(16,0,0,16,86.6562,0)">
            <path
              d="M0.4,0.019C0.489,0.019 0.562,-0.004 0.62,-0.048C0.647,-0.07 0.659,-0.095 0.654,-0.123C0.651,-0.142 0.641,-0.157 0.625,-0.167C0.61,-0.176 0.595,-0.177 0.581,-0.169C0.556,-0.155 0.529,-0.144 0.498,-0.136C0.467,-0.128 0.44,-0.124 0.415,-0.124C0.356,-0.124 0.311,-0.143 0.281,-0.182C0.251,-0.221 0.236,-0.278 0.236,-0.353C0.236,-0.392 0.24,-0.425 0.247,-0.454C0.253,-0.482 0.262,-0.505 0.273,-0.521C0.283,-0.538 0.297,-0.551 0.314,-0.56C0.331,-0.57 0.348,-0.576 0.364,-0.579C0.38,-0.582 0.4,-0.583 0.421,-0.583C0.462,-0.583 0.507,-0.573 0.556,-0.551C0.568,-0.547 0.579,-0.544 0.589,-0.544C0.6,-0.544 0.609,-0.547 0.616,-0.552C0.624,-0.557 0.63,-0.563 0.636,-0.571C0.642,-0.578 0.645,-0.586 0.647,-0.596C0.649,-0.606 0.649,-0.616 0.647,-0.625C0.646,-0.634 0.642,-0.644 0.636,-0.653C0.63,-0.663 0.622,-0.671 0.611,-0.678C0.58,-0.694 0.554,-0.705 0.531,-0.711C0.495,-0.721 0.455,-0.726 0.411,-0.726C0.36,-0.726 0.312,-0.717 0.268,-0.7C0.224,-0.683 0.186,-0.658 0.154,-0.627C0.121,-0.595 0.096,-0.555 0.077,-0.507C0.059,-0.459 0.05,-0.406 0.05,-0.348C0.05,-0.29 0.059,-0.237 0.077,-0.19C0.096,-0.144 0.121,-0.105 0.153,-0.075C0.185,-0.045 0.222,-0.022 0.264,-0.006C0.306,0.01 0.352,0.019 0.4,0.019Z"
              :style="{ fill: fillColor, fillRule: 'nonzero' }"
            />
          </g>
          <g transform="matrix(16,0,0,16,97.6016,0)">
            <path
              d="M0.161,0L0.512,0C0.53,0 0.545,-0.006 0.557,-0.019C0.569,-0.031 0.575,-0.047 0.575,-0.065C0.575,-0.084 0.569,-0.1 0.557,-0.112C0.545,-0.125 0.53,-0.131 0.512,-0.131L0.248,-0.131L0.248,-0.295L0.478,-0.295C0.497,-0.295 0.513,-0.301 0.526,-0.314C0.539,-0.326 0.546,-0.342 0.546,-0.36C0.546,-0.379 0.539,-0.395 0.526,-0.407C0.513,-0.42 0.497,-0.427 0.478,-0.427L0.248,-0.427L0.248,-0.579L0.5,-0.579C0.519,-0.579 0.535,-0.585 0.548,-0.597C0.561,-0.61 0.568,-0.625 0.568,-0.644C0.568,-0.663 0.561,-0.678 0.548,-0.691C0.535,-0.704 0.519,-0.71 0.5,-0.71L0.186,-0.71C0.166,-0.71 0.149,-0.708 0.135,-0.703C0.122,-0.699 0.111,-0.692 0.103,-0.685C0.095,-0.677 0.089,-0.666 0.084,-0.654C0.079,-0.641 0.076,-0.628 0.075,-0.615C0.074,-0.603 0.073,-0.587 0.073,-0.568L0.073,-0.125C0.073,-0.109 0.075,-0.095 0.078,-0.081C0.081,-0.067 0.086,-0.053 0.092,-0.041C0.099,-0.028 0.108,-0.018 0.12,-0.011C0.131,-0.004 0.145,0 0.161,0Z"
              :style="{ fill: fillColor, fillRule: 'nonzero' }"
            />
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Logo",
  props: {
    fillColor: {
      type: String,
      default: "rgb(255,255,255)",
    },
  },
};
</script>
