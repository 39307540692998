<template>
  <div>
    Tutorial V2 - Original Tutorial component moved to Component Archive
  </div>
</template>

<script>
export default {
  name: "Tutorial",
};
</script>

<style></style>
