<template>
  <div>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 226 255"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xml:space="preserve"
      xmlns:serif="http://www.serif.com/"
      style="
        fill-rule: evenodd;
        clip-rule: evenodd;
        stroke-linejoin: round;
        stroke-miterlimit: 2;
        filter: drop-shadow(rgba(14, 30, 37, 0.16) 0px 0px 10px);
      "
    >
      <defs>
        <linearGradient id="defaultGradient" gradientTransform="rotate(90)">
          <stop offset="5%" stop-color="#6846b7" />
          <stop offset="95%" stop-color="#7b4bc7" />
        </linearGradient>
      </defs>
      <g transform="matrix(1,0,0,1,0,-2.5)">
        <path
          d="M103.923,5C109.697,1.667 115.47,1.667 121.244,5L216.506,60C222.28,63.333 225.167,68.333 225.167,75L225.167,185C225.167,191.667 222.28,196.667 216.506,200L121.244,255C115.47,258.333 109.697,258.333 103.923,255L8.66,200C2.887,196.667 0,191.667 0,185L0,75C0,68.333 2.887,63.333 8.66,60L103.923,5Z"
          style="fill: white; fill-rule: nonzero"
        />
        <g transform="matrix(0.823928,0,0,0.823928,19.8228,22.8894)">
          <path
            fill="url('#defaultGradient')"
            d="M103.923,5C109.697,1.667 115.47,1.667 121.244,5L216.506,60C222.28,63.333 225.167,68.333 225.167,75L225.167,185C225.167,191.667 222.28,196.667 216.506,200L121.244,255C115.47,258.333 109.697,258.333 103.923,255L8.66,200C2.887,196.667 0,191.667 0,185L0,75C0,68.333 2.887,63.333 8.66,60L103.923,5Z"
            style="fill-rule: nonzero"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "SampleAvatar",
};
</script>

<style scoped>
svg {
  width: 120px;
}
</style>
